<template>
  <n-card :title="headerText">
    <n-space>
      <n-button @click="downloadCsv">
        Export CSV
      </n-button>
    </n-space>
  </n-card>
  <n-data-table id="history-table" ref="historyTable" :columns="columns" :data="history" :pagination="pagination" striped />
</template>
<script src="./HistoryData.js" />
<style lang="scss" scoped src="./HistoryData.scss" />
