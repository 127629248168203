import { ref } from 'vue';
import Dexie from 'dexie';
import { importDB, exportDB } from "dexie-export-import";
import { useDialog } from 'naive-ui';
import state from '@/state';

export default {
  name: 'DataBackupRestore',
  setup() {
    const dialog = useDialog();

    return { dialog }
  },
  data: () => {
    return {
      printerName: null,
      isLoading: ref(false),
      isLoadingRestore: ref(false),
      selectFolder: false,
    }
  },
  computed: {
    headerText() {
      return 'Backup/Restore - ' + this.printerName;
    },
  },
  methods: {
    showError(message) {
      window.$message.error(message, {
        closable: true,
        duration: 10000,
        keepAliveOnHover: true,
      });
    },
    showSuccess(message) {
      window.$message.success(message, {
        closable: true,
        duration: 6000,
      });
    },
    backupClick() {
      this.isLoading = true;
      
      this.dialog.warning({
        title: 'Backup App Data?',
        content: 'Are you sure you want backup the app data, if a backup already exists, it will be overwritten?',
        positiveText: 'Yes',
        negativeText: 'No',
        onPositiveClick: () => {
          this.backupDatabase()
        },
        onNegativeClick: () => {
          this.isLoading = false;
          return true;
        },
      });
    },
    restoreClick() {
      this.isLoadingRestore = true;
      
      this.dialog.warning({
        title: 'Restore App Data?',
        content: 'Are you sure you want restore the app data, all data in the app will be overwritten?',
        positiveText: 'Yes',
        negativeText: 'No',
        onPositiveClick: () => {
          this.restoreDatabase()
        },
        onNegativeClick: () => {
          this.isLoadingRestore = false;
          return true;
        },
      });
    },
    async backupDatabase() {
      const locale = await state.getLocale();
      
      try {
        const db = await new Dexie('merchrDB').open();
        const backupFileHandle = await window.$handle.getFileHandle('backup.mpab', { create: true });
        const writable = await backupFileHandle.createWritable();
        const content = await exportDB(db);
        await writable.write(content);
        await writable.close();
        
        this.showSuccess('The backup file has been successfully created/updated!');
        
        // Update history
        const history = {
          type: 'App data backup created successfully',
          job: 0,
          message: 'The backup file has been successfully created/updated',
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      } catch (error) {
        this.showError('The backup file failed to be created/updated, contact Merchr support if this issue persists!');
        
        // Update history
        const history = {
          type: 'App data backup failed to be created',
          job: 0,
          message: `The backup file failed to be created/updated! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
      
      this.isLoading = false;
    },
    async restoreDatabase() {
      const locale = await state.getLocale();

      try {
        const backupFileHandle = await window.$handle.getFileHandle('backup.mpab');
        const file = await backupFileHandle.getFile();
        //const contents = await file.text();
        
        await importDB(file, { overwriteValues: true });
        
        this.showSuccess('The app data has been restored, you will need to reselect the file download folder!');
        
        // Update history
        const history = {
          type: 'App data restored successfully',
          job: 0,
          message: 'The app data has been successfully restored',
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
        
        this.selectFolder = true;
      } catch (error) {
        this.showError('The app failed to be restored, contact Merchr support if this issue persists!');
        
        // Update history
        const history = {
          type: 'App data failed to be restored',
          job: 0,
          message: `The app failed to be restored! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
      
      this.isLoadingRestore = false;
    },
    async selectArtworkFolder(event) {
      event.preventDefault();
      
      const locale = await state.getLocale();

      try {
        const dirHandle = await window.showDirectoryPicker({
          id: "merchr",
          mode: "readwrite",
          startIn: "desktop"
        });

        await state.setDirectoryHandle(dirHandle);
        
        this.showSuccess('The file download folder has been set successfully!');
        
        const history = {
          type: 'File Download Folder Selected',
          job: 0,
          message: 'The file download folder has been selected successfully',
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
        
        this.selectFolder = false;
      } catch (error) {
        this.showError(`Error Name: ${error.name} - Error Message: ${error.message}`);
        
        const history = {
          type: 'File Download Folder Selection Failed',
          job: 0,
          message: `The file download folder failed to be selected! 
          Error Name: ${error.name} - Error Message: ${error.message}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
    },
  },
  async mounted() {
    this.printerName = await state.getPrinterName();
  },
}