import { openDB } from 'idb';

const DB_NAME = 'merchrDB';
const DB_VERSION = 1.0;
const OBS_NAME_HISTORY = 'merchrHistory';
const OBS_NAME_JOBS = 'merchrJobs';
const OBS_NAME_JOBS_PAST = 'merchrJobsPast';
const OBS_NAME_SETTINGS = 'merchrSettings';
const OBS_NAME_HANDLES = 'merchrHandles';
const OBS_NAME_USERS = 'merchrUsers';
let DB;

export default class IndexedDbService {

  async getDb() {
    if (!('indexedDB' in window)) {
      throw new Error('Browser does not support IndexedDB')
    }
    return DB = openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore(OBS_NAME_HISTORY);
        db.createObjectStore(OBS_NAME_JOBS);
        db.createObjectStore(OBS_NAME_JOBS_PAST);
        db.createObjectStore(OBS_NAME_SETTINGS);
        db.createObjectStore(OBS_NAME_HANDLES);
        db.createObjectStore(OBS_NAME_USERS);
      },
    })
  }

  getTables() {
    return {
      history: OBS_NAME_HISTORY,
      jobs: OBS_NAME_JOBS,
      jobs_past: OBS_NAME_JOBS_PAST,
      settings: OBS_NAME_SETTINGS,
      handles: OBS_NAME_HANDLES,
      users: OBS_NAME_USERS,
    };
  }

  async get(table, key) {
    return (await DB).get(table, key);
  }

  async set(table, key, val) {
    return (await DB).put(table, val, key);
  }

  async del(table, key) {
    return (await DB).delete(table, key);
  }

  async keys(table) {
    return (await DB).getAllKeys(table);
  }

  async getAll(table) {
    return (await DB).getAll(table);
  }

}
