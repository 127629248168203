<template>
<n-layout position="absolute" has-sider>
  <app-sidebar/>
  <n-layout :native-scrollbar="false">
    <app-header/>
    <n-layout-content content-style="padding: 24px; min-height: 91vh">
      <router-viewer/>
    </n-layout-content>
    <app-footer/>
  </n-layout>
</n-layout>
</template>

<script setup>
import RouterViewer from '@/components/RouterViewer.vue';
import AppSidebar from '@/layouts/AppSidebar.vue';
import AppFooter from '@/layouts/AppFooter.vue';
import AppHeader from '@/layouts/AppHeader.vue';
import { useMessage } from 'naive-ui';

window.$message = useMessage();
</script>

<style scoped>
.n-layout {
  background-color: #f4f7fd;
}
</style>
