import { ref } from 'vue';
import state from '@/state';

export default {
  name: 'PrinterSettings',
  data: () => {
      return {
        formRefConnect: ref(null),
        formRefPrinter: ref(null),
        formRefSettings: ref(null),
        isLoading: false,
        isLoadingPrinter: false,
        isLoadingSettings: false,
        formValueToken: ref({
          token: null
        }),
        formValuePrinter: ref({
          printer: null
        }),
        formValueSettings: ref({
          name: null,
          locale: null,
          folder: null,
          delay: null,
          pcname: null,
        }),
        rulesConnect: {
          token: {
            required: true,
            message: 'Please enter the Merchr Hub access token',
            trigger: ["blur", "input"],
          }, 
        },
        rulesSettings: {
          name: {
            required: true,
            message: 'Please enter the partners name',
            trigger: ["blur", "input"],
          },
          locale: {
            required: true,
            message: 'Please select the app locale',
            trigger: ["blur", "change"],
          },
          folder: {
            required: true,
            message: 'Please select the artwork folder name',
            trigger: ["blur", "change"],
          },
          delay: {
            required: true,
            message: 'Please select the delete delay',
            trigger: ["blur", "change"],
          },
          pcname: {
            required: true,
            message: 'Please enter the PC name',
            trigger: ["blur", "input"],
          },
        },
        printerOptions: [],
        printerDetails: [],
        localeOptions: [],
        delayOptions: [],
        folderOptions: [],
        accessToken: null,
        sessionToken: null,
        printerId: 0,
        printerName: null,
        printerType: null,
        printerSerial: null,
        printerUuid: null,
        setPrinterUpdate: false,
        version: null,
      }
  },
  computed: {
    formattedPrinterType() {
      if (this.printerType !== null) {
        let capitalized = []

        this.printerType.split(' ').forEach(word => {
          capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
          )
        });

        return capitalized.join(' ');
      }
    }
  },
  methods: {
    showError(message) {
      window.$message.error(message, {
        closable: true,
        duration: 8000,
        keepAliveOnHover: true,
      });
    },
    showSuccess(message) {
      window.$message.success(message, {
        closable: true,
        duration: 6000,
      });
    },
    onMessageSettings(event) {
        const locale = state.getLocale();
        const data = JSON.parse(event.data);

        this.isLoading = false;
        this.isLoadingPrinter = false;

        if (data.status === 'success') {

          if (data.type === 'printers') {
            data.printers.forEach(item => {
              let id = item.id;
              let name = item.name;
              let type = item.printer_type.jig_type;
              let uuid = item.uuid;
              let serial = item.serial_number;

              this.printerOptions.push({
                label: `${item.name} - ${item.serial_number}`,
                value: id,
              });

              this.printerDetails[id] = {
                name: name,
                type: type,
                uuid: uuid,
                serial: serial,
              }
            });
          }
          if (data.type === 'printer_set') {
            if (this.setPrinterUpdate) {
              this.setPrinterUpdate = false;
              state.setPrinterId(data.printer_id);
              state.setPrinterName(this.printerDetails[data.printer_id].name);
              state.setPrinterType(this.printerDetails[data.printer_id].type);
              state.setPrinterUuid(this.printerDetails[data.printer_id].uuid);
              state.setPrinterSerialNumber(this.printerDetails[data.printer_id].serial);
              this.printerId = data.printer_id;
              this.printerName = this.printerDetails[data.printer_id].name;
              this.printerType = this.printerDetails[data.printer_id].type;
              this.printerSerial = this.printerDetails[data.printer_id].serial;
              this.printerUuid = this.printerDetails[data.printer_id].uuid;
              this.showSuccess('Printer has been set!');
              
              const history = {
                type: 'Printer Selection Updated',
                job: 0,
                message: 'The selected printer has been successfully updated.',
                date: new Date().toLocaleString(locale)
              };
              state.addHistory(history);
            }
          }

        }
    },
    async selectArtworkFolder(event) {
      event.preventDefault();
      
      const locale = await state.getLocale();

      try {
        const dirHandle = await window.showDirectoryPicker({
          id: "merchr",
          mode: "readwrite",
          startIn: "desktop"
        });

        await state.setDirectoryHandle(dirHandle);
        
        this.showSuccess('The file download folder has been set successfully!');
        
        const history = {
          type: 'File Download Folder Selected',
          job: 0,
          message: 'The file download folder has been selected successfully',
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      } catch (error) {
        this.showError(`Error Name: ${error.name} - Error Message: ${error.message}`);
        
        const history = {
          type: 'File Download Folder Selection Failed',
          job: 0,
          message: `The file download folder failed to be selected! 
          Error Name: ${error.name} - Error Message: ${error.message}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
    },
    async updateAccessToken(event) {
      event.preventDefault();

      this.isLoading = true;

      await state.setToken(this.formValueToken.token);
      this.showSuccess('The access token has been updated, close and open the app for the changes to take effect.');
      this.isLoading = false;
      
      const locale = await state.getLocale();
      const history = {
        type: 'Access Token Updated',
        job: 0,
        message: 'The access token has been successfully updated.',
        date: new Date().toLocaleString(locale)
      };
      state.addHistory(history);
    },
    async setPrinter(event) {
        event.preventDefault();

        // Check we have a printer ID
        if (this.formValuePrinter.printer === null) {
            this.showError('Please select a printer!');
            return;
        }

        // Check we have a different printer ID
        if (this.formValuePrinter.printer === this.printerId) {
          this.showError('The printer chosen has already been assigned!');
          return;
        }

        this.isLoadingPrinter = true;
        this.setPrinterUpdate = true;

        // Set Printer ID on WS Server
        const message = {
          type: "set_printer",
          printer_id: this.formValuePrinter.printer,
          session_token: this.sessionToken
        };

        // Send request
        window.$websocket.send(JSON.stringify(message));
    },
    async updateSettings() {
      this.isLoadingSettings = true;

      await state.setPartnerName(this.formValueSettings.name);
      await state.setLocale(this.formValueSettings.locale);
      await state.setFolder(this.formValueSettings.folder);
      await state.setDeleteDelay(this.formValueSettings.delay);
      await state.setPcName(this.formValueSettings.pcname);

      this.showSuccess('Settings have been successfully updated.');

      this.isLoadingSettings = false;
      
      const locale = await state.getLocale();
      const history = {
        type: 'Printer App Settings Updated',
        job: 0,
        message: 'The printer app settings have been successfully updated.',
        date: new Date().toLocaleString(locale)
      };
      state.addHistory(history);
    },
  },
  async mounted() {
    this.version = process.env.VUE_APP_VERSION;

    window.$websocket.addEventListener("message", this.onMessageSettings);

    // Set printer ID
    this.printerId = await state.getPrinterId();
    this.formValuePrinter.printer = this.printerId;

    // Set tokens
    this.accessToken = await state.getToken();
    this.sessionToken = await state.getSessionToken();

    // Set printer details
    this.printerName = await state.getPrinterName();
    this.printerType = await state.getPrinterType();
    this.printerSerial = await state.getPrinterSerialNumber();
    this.printerUuid = await state.getPrinterUuid();

    // Set settings select options
    this.localeOptions = [
      {
        label: 'en-US',
        value: 'en-US'
      },
      {
        label: 'en-GB',
        value: 'en-GB'
      },
    ];
    this.folderOptions = [
      {
        label: 'Barcode',
        value: 'barcode'
      },
      {
        label: 'Job ID',
        value: 'job_id'
      },
      {
        label: 'External ID',
        value: 'external_id'
      },
    ];
    this.delayOptions = [
      {
        label: '7 Days',
        value: '7d'
      },
      {
        label: '14 Days',
        value: '14d'
      },
      {
        label: '1 Month',
        value: '1m'
      },
      {
        label: '2 Months',
        value: '2d'
      },
      {
        label: '3 Months',
        value: '3m'
      },
    ];

    // Set default settings values
    this.formValueSettings.name = await state.getPartnerName();
    this.formValueSettings.locale = await state.getLocale();
    this.formValueSettings.folder = await state.getFolder();
    this.formValueSettings.delay = await state.getDeleteDelay();
    this.formValueSettings.pcname = await state.getPcName();

    // Now get printers
    const message = {
      type: "get_printers",
      session_token: this.sessionToken
    };

    // Send request
    window.$websocket.send(JSON.stringify(message));
  },
  async unmounted() {
    window.$websocket.removeEventListener("message", this.onMessageSettings);
  },
}