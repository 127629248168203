<template>
  <n-layout-sider
    :width="220"
    :native-scrollbar="false"
    :collapsed="collapsed"
    collapse-mode="width"
    show-trigger
    bordered
    @update:collapsed="collapsed = !collapsed"
  >
    <router-link to="/" #="{ navigate, href }" custom>
      <n-a class="logo" :href="href" @click="navigate">
        <img src="~@/assets/images/merchr-logo.png" alt="logo" style="width: 170px" />
      </n-a>
    </router-link>
    <n-menu :options="menuOptions" :root-indent="18" />
  </n-layout-sider>
</template>
<script src="./AppSidebar.js" />
<style lang="scss" scoped src="./AppSidebar.scss" />
