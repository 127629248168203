import { ref } from 'vue';
import axios from "axios";
import state from '@/state';

export default {
  name: 'SetupAuthentication',
  data: () => {
      return {
        formRefConnect: ref(null),
        formRefPrinter: ref(null),
        intro: true,
        connectForm: true,
        printerForm: false,
        folderSelect: false,
        completed: false,
        isLoading: false,
        isLoadingPrinter: false,
        formValueConnect: ref({
          token: null
        }),
        formValuePrinter: ref({
          printer: null
        }),
        rulesConnect: {
          token: {
            required: true,
            message: 'Please enter the Merchr Hub access token',
            trigger: ["blur", "input"],
          }, 
        },
        printerOptions: [],
        printerDetails: [],
      }
  },
  methods: {
    showError(message) {
      window.$message.error(message, {
        closable: true,
        duration: 8000,
        keepAliveOnHover: true,
      });
    },
    showSuccess(message) {
      window.$message.success(message, {
        closable: true,
        duration: 5000,
      });
    },
    connectToWsServer() {
      return new Promise((resolve, reject) => {
        window.$websocket = new WebSocket(this.wsAddress);

        window.$websocket.onopen = () => resolve(window.$websocket);

        window.$websocket.onerror = () => reject(new Error("Unable to connect to web socket server!")); 
      });
    },
    onMessage(event) {
        const data = JSON.parse(event.data);

        if (data.status === 'success') {
          if (data.type === 'authenticate') {
            const message = {
              type: "authenticate",
              token: this.formValueConnect.token
            };

            // Send authentication
            window.$websocket.send(JSON.stringify(message));
          }
          if (data.type === 'authenticated') {
            // Set connected
            state.setConnected();

            // Set session token
            state.setSessionToken(data.session_token);

            // Show message
            this.showSuccess('Authenticated and connected, now fetching printers...'); 

            // Now get printers
            const message = {
              type: "get_printers",
              session_token: data.session_token
            };

            // Send request
            window.$websocket.send(JSON.stringify(message));
          }
          if (data.type === 'printers') {
            data.printers.forEach(item => {
              let id = item.id;
              let name = item.name;
              let type = item.printer_type.jig_type;
              let uuid = item.uuid;
              let serial = item.serial_number;

              this.printerOptions.push({
                label: `${item.name} - ${item.serial_number}`,
                value: id,
              });

              this.printerDetails[id] = {
                name: name,
                type: type,
                uuid: uuid,
                serial: serial,
              }
            });

            // Hide connect form and show printer select
            this.connectForm = false;
            this.printerForm = true;
          }
          if (data.type === 'printer_set') {
            this.isLoadingPrinter = false;
            state.setNotConnected();
            state.setSessionToken(null);
            window.$websocket.close();
            window.$websocket = null;

            // Load dashboard
            this.printerForm = false;
            this.folderSelect = true;
          }
        } else {
          this.showError(`Error: ${data.type} Message: ${data.message}`);
        }
    },
    finish() {
      window.location.replace('/dashboard');
    },
    async authenticate(event) {
      event.preventDefault();

      const valid = await this.$refs.formRefConnect.validate().catch(error => console.log(error));

      if (valid) {
        const url = this.apiAddress + "me";
        const headers = {
          Authorization: `Bearer ${this.formValueConnect.token}`,
          Accept: 'application/json',
        };

        this.isLoading = true;

        await axios
          .get(url, { headers: headers })
          .then(async response => {
            const auth = response.data;

            if(typeof auth.data.id !== "undefined") {
              // Save key and set authenticated 
              state.setToken(this.formValueConnect.token);
              state.setAuthenticated();

              // Now connect to WS Server and get the printers
              this.connectToWsServer()
              .then(ws => {
                // Set message listener
                ws.onmessage = (event) => {
                  this.onMessage(event);
                };
              })
              .catch(error => {
                this.showError(`Please check your authentication token and try again! Error Received: ${error}`);
              });
            } else {
              this.showError('Please check your authentication token and try again!');
            }
          })
          .catch(error => {
            this.showError(`Please check your authentication token and try again! Error Received: ${error}`);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    async setPrinter(event) {
        event.preventDefault();

        // Check we have a printer ID
        if(this.formValuePrinter.printer === null) {
            this.showError('Please select a printer!');
            return;
        }

        this.isLoadingPrinter = true;

        // Save the printer details
        state.setPrinterId(this.formValuePrinter.printer);
        state.setPrinterName(this.printerDetails[this.formValuePrinter.printer].name);
        state.setPrinterType(this.printerDetails[this.formValuePrinter.printer].type);
        state.setPrinterUuid(this.printerDetails[this.formValuePrinter.printer].uuid);
        state.setPrinterSerialNumber(this.printerDetails[this.formValuePrinter.printer].serial);

        // Set Printer ID on WS Server
        const message = {
          type: "set_printer",
          printer_id: this.formValuePrinter.printer,
          session_token: state.getSessionToken()
        };

        // Send request
        window.$websocket.send(JSON.stringify(message));
    },
    async selectArtworkFolder(event) {
      event.preventDefault();
      
      const locale = await state.getLocale();

      try {
        const dirHandle = await window.showDirectoryPicker({
          id: "merchr",
          mode: "readwrite",
          startIn: "desktop"
        });

        await state.setDirectoryHandle(dirHandle);
        
        this.showSuccess('The file download folder has been set successfully!');
        
        this.folderSelect = false;
        this.intro = false;
        this.completed = true;
        
        const history = {
          type: 'File Download Folder Selected',
          job: 0,
          message: 'The file download folder has been selected successfully',
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      } catch (error) {
        this.showError(`Error Name: ${error.name} - Error Message: ${error.message}`);
        
        const history = {
          type: 'File Download Folder Selection Failed',
          job: 0,
          message: `The file download folder failed to be selected! 
          Error Name: ${error.name} - Error Message: ${error.message}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
    },
  },
  mounted() {
    state.setPartnerName('');
    state.setLocale('en-US');
    state.setFolder('barcode');
    state.setDeleteDelay('14d');
  },
}