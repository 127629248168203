<template>
  <n-layout-header bordered>
    <n-grid :cols="2">
      <n-gi>
        <div class="header-col-1">
          New Jobs:
          <n-badge type="success" :value="newJobCount" show-zero />
        </div>
      </n-gi>
      <n-gi>
        <div class="header-col-2">
          <div v-show="connected === true" class="header-connect-status header-connected">
            <app-icon :type="'DotCircleRegular'" size="18" color="#008000" /> Connected
          </div>
          <div v-show="connected === false" class="header-connect-status header-disconnected">
            <n-button type="error" size="tiny" @click="reconnect" ghost>
              RECONNECT
            </n-button>
            <app-icon v-show="connected === false" :type="'DotCircleRegular'" size="18" color="#cc0000" /> Disconnected
          </div>
        </div>
      </n-gi>
    </n-grid>
  </n-layout-header>
</template>
<script src="./AppHeader.js" />
<style lang="scss" scoped src="./AppHeader.scss" />
