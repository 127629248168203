<template>
  <n-card :title="headerText"></n-card>
  <n-card>
    <n-space class="settings-intro">
      Click the 'Backup App' button below to save all app data to the attached folder.
    </n-space>
    <n-space>
      <div class="no-form">
        <n-button type="primary" size="large" :loading="isLoading" @click="backupClick">
          Backup App
        </n-button>
      </div>
    </n-space>
  </n-card>
  <n-card>
    <n-space class="settings-intro">
      Click the 'Restore App' button below to restore all the apps data from the last backup.
    </n-space>
    <n-space class="settings-intro">
      Once you have restored the app, you must select the download folder again, button shows below after successful restore. 
      When all has been completed it is advised to close and reopen the printer app.
    </n-space>
    <n-space>
      <div class="no-form">
        <n-button type="primary" size="large" :loading="isLoadingRestore" @click="restoreClick">
          Restore App
        </n-button>
      </div>
    </n-space>
    <n-space v-show="selectFolder === true">
      <n-button type="primary" size="large" id="restore-artwork-btn" block @click="selectArtworkFolder">
        Select Artwork Folder
      </n-button>
    </n-space>
  </n-card>
</template>
<script src="./DataBackupRestore.js" />
<style lang="scss" scoped src="./DataBackupRestore.scss" />
