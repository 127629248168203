import { reactive } from 'vue';
import IndexedDbService from "./services/indexedDBService.js";

const indexedDbService = new IndexedDbService();
indexedDbService.getDb();
const dbTables = indexedDbService.getTables();

const stateData = reactive({
  token: null,
  sessionToken: null,
  authenticated: false,
  connected: false,
  partnerName: null,
  pcName: null,
  locale: null,
  folder: null,
  deleteDelay: null,
  printerId: null,
  printerName: null,
  printerType: null,
  printerUuid: null,
  printerSerial: null,
  printers: null,
  job: null,
  jobs: null,
  jobsPast: null,
  history: null,
});

const state = {
  setToken: (token) => {
    stateData.token = token;
    indexedDbService.set(dbTables.settings, 'token', token);
  },
  getToken: () => {
    if(stateData.token !== null) {
      return stateData.token;
    }
    stateData.token = indexedDbService.get(dbTables.settings, 'token') ?? null;
    return stateData.token;
  },
  setSessionToken: (token) => {
    stateData.sessionToken = token;
    indexedDbService.set(dbTables.settings, 'sessionToken', token);
  },
  getSessionToken: () => {
    if(stateData.sessionToken !== null) {
      return stateData.sessionToken;
    }
    stateData.sessionToken = indexedDbService.get(dbTables.settings, 'sessionToken') ?? null;
    return stateData.sessionToken;
  },
  setAuthenticated: () => {
    stateData.authenticated = true;
    indexedDbService.set(dbTables.settings, 'authenticated', true);
  },
  setUnAuthenticated: () => {
    stateData.authenticated = false;
    indexedDbService.set(dbTables.settings, 'authenticated', false);
  },
  getAuthenticated: () => {
    return stateData.authenticated;
  },
  setConnected: () => {
    stateData.connected = true;
  },
  setNotConnected: () => {
    stateData.connected = false;
  },
  getConnected: () => {
    return stateData.connected;
  },
  setPartnerName: (name) => {
    stateData.partnerName = name;
    indexedDbService.set(dbTables.settings, 'name', name);
  },
  getPartnerName: () => {
    if(stateData.partnerName !== null) {
      return stateData.partnerName;
    }
    stateData.partnerName = indexedDbService.get(dbTables.settings, 'name') ?? null;
    return stateData.partnerName;
  },
  setPcName: (name) => {
    stateData.pcName = name;
    indexedDbService.set(dbTables.settings, 'pcname', name);
  },
  getPcName: () => {
    if(stateData.pcName !== null) {
      return stateData.pcName;
    }
    stateData.pcName = indexedDbService.get(dbTables.settings, 'pcname') ?? null;
    return stateData.pcName;
  },
  
  setLocale: (locale) => {
    stateData.locale = locale;
    indexedDbService.set(dbTables.settings, 'locale', locale);
  },
  getLocale: () => {
    if(stateData.locale !== null) {
      return stateData.locale;
    }
    stateData.locale = indexedDbService.get(dbTables.settings, 'locale') ?? null;
    return stateData.locale;
  },
  setFolder: (folder) => {
    stateData.folder = folder;
    indexedDbService.set(dbTables.settings, 'folder', folder);
  },
  getFolder: () => {
    if(stateData.folder !== null) {
      return stateData.folder;
    }
    stateData.folder = indexedDbService.get(dbTables.settings, 'folder') ?? null;
    return stateData.folder;
  },
  setDeleteDelay: (delay) => {
    stateData.deleteDelay = delay;
    indexedDbService.set(dbTables.settings, 'delay', delay);
  },
  getDeleteDelay: () => {
    if(stateData.deleteDelay !== null) {
      return stateData.deleteDelay;
    }
    stateData.deleteDelay = indexedDbService.get(dbTables.settings, 'delay') ?? null;
    return stateData.deleteDelay;
  },
  setPrinterId: (id) => {
    stateData.printerId = id;
    indexedDbService.set(dbTables.settings, 'printerId', id);
  },
  getPrinterId: () => {
    if(stateData.printerId !== null) {
      return stateData.printerId;
    }
    stateData.printerId = indexedDbService.get(dbTables.settings, 'printerId') ?? null;
    return stateData.printerId;
  },
  setPrinterName: (name) => {
    stateData.printerName = name;
    indexedDbService.set(dbTables.settings, 'printerName', name);
  },
  getPrinterName: () => {
    if(stateData.printerName !== null) {
      return stateData.printerName;
    }
    stateData.printerName = indexedDbService.get(dbTables.settings, 'printerName') ?? null;
    return stateData.printerName;
  },
  setPrinterType: (type) => {
    stateData.printerType = type;
    indexedDbService.set(dbTables.settings, 'printerType', type);
  },
  getPrinterType: () => {
    if(stateData.printerType !== null) {
      return stateData.printerType;
    }
    stateData.printerType = indexedDbService.get(dbTables.settings, 'printerType') ?? null;
    return stateData.printerType;
  },
  setPrinterUuid: (uuid) => {
    stateData.printerUuid = uuid;
    indexedDbService.set(dbTables.settings, 'printerUuid', uuid);
  },
  getPrinterUuid: () => {
    if(stateData.printerUuid !== null) {
      return stateData.printerUuid;
    }
    stateData.printerUuid = indexedDbService.get(dbTables.settings, 'printerUuid') ?? null;
    return stateData.printerUuid;
  },
  setPrinterSerialNumber: (number) => {
    stateData.printerSerial = number;
    indexedDbService.set(dbTables.settings, 'printerSerial', number);
  },
  getPrinterSerialNumber: () => {
    if(stateData.printerSerial !== null) {
      return stateData.printerSerial;
    }
    stateData.printerSerial = indexedDbService.get(dbTables.settings, 'printerSerial') ?? null;
    return stateData.printerSerial;
  },
  setDirectoryHandle: (handle) => {
    indexedDbService.set(dbTables.handles, 'artwork', handle);
  },
  getDirectoryHandle: () => {
    return indexedDbService.get(dbTables.handles, 'artwork') ?? null;
  },
  getPrintJob: (key) => {
    stateData.job = indexedDbService.get(dbTables.jobs, key) ?? null;
    return stateData.job;
  },
  getPrintJobs: () => {
    stateData.jobs = indexedDbService.getAll(dbTables.jobs) ?? null;
    return stateData.jobs;
  },
  addUpdatePrintJob: (key, val) => {
    const addUpdate = indexedDbService.set(dbTables.jobs, key, val);
    stateData.jobs = indexedDbService.getAll(dbTables.jobs) ?? null;
    return addUpdate;
  },
  deletePrintJob: (key) => {
    const deleteJob = indexedDbService.del(dbTables.jobs, key);
    stateData.jobs = indexedDbService.getAll(dbTables.jobs) ?? null;
    return deleteJob;
  },
  getSinglePrintJobStatus: (key) => {
    if(stateData.JobStatusSingle !== null) {
      return stateData.JobStatusSingle;
    }
    stateData.JobStatusSingle = indexedDbService.get(dbTables.job_status, key) ?? null;
    return stateData.JobStatusSingle;
  },
  getPrintPastJobs: () => {
    stateData.jobsPast = indexedDbService.getAll(dbTables.jobs_past) ?? null;
    return stateData.jobsPast;
  },
  addUpdatePrintPastJob: (key, val) => {
    const addUpdate = indexedDbService.set(dbTables.jobs_past, key, val);
    stateData.jobs_past = indexedDbService.getAll(dbTables.jobs_past) ?? null;
    return addUpdate;
  },
  deletePrintPastJob: (key) => {
    const deleteJob = indexedDbService.del(dbTables.jobs_past, key);
    stateData.jobs_past = indexedDbService.getAll(dbTables.jobs_past) ?? null;
    return deleteJob;
  },
  getHistory: () => {
    stateData.history = indexedDbService.getAll(dbTables.history) ?? null;
    return stateData.history;
  },
  addHistory: (val) => {
    const date = new Date();
    const add = indexedDbService.set(dbTables.history, date, val);
    stateData.history = indexedDbService.getAll(dbTables.history) ?? null;
    return add;
  },
};

export default state;
