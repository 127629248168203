<template>
  <div class="auth-layout">
    <n-h1>
      <img src="~@/assets/images/merchr-logo.png" alt="logo" class="brand-logo" />
    </n-h1>
    <n-card size="large">
      <router-viewer />
    </n-card>
    <n-space justify="center" class="setup-footer">
      <n-a href="https://help.merchr.com/en" target="_blank">Help</n-a>
      <n-a href="https://merchr.com/privacy-policy/" target="_blank">Privacy Policy</n-a>
      <n-a href="https://merchr.com/terms-of-service/" target="_blank">Terms of Service</n-a>
    </n-space>
  </div>
</template>
<script setup>
import RouterViewer from '@/components/RouterViewer.vue';
import { useMessage } from 'naive-ui';

window.$message = useMessage();
</script>
<style scoped>
.auth-layout {
  justify-content: center;
  align-items: center;
}

.n-h1 {
  margin: 15vh auto 10px;
  text-align: center;
}
.brand-logo {
    width: 100%;
    max-width: 280px;
}
.n-card {
  margin: 0 auto;
  max-width: 540px;
  box-shadow: var(--box-shadow);
}
.setup-footer {
  margin: 10px 0px 10px 0px;
  font-size: 10px;
}
</style>
