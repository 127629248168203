<template>
  <config-provider>
    <component :is="layout" />
  </config-provider>
</template>

<script setup>
import ConfigProvider from '@/components/ConfigProvider.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import 'vfonts/Lato.css';

const route = useRoute();

const layout = computed({
  get: () => route.meta.layout ?? 'app-layout',
});

window.$websocket = null;
window.$handle = null;
window.$newJobs = {
  new: false,
  updated: false,
  count: 0,
  total: 0,
}
window.$newJobsHeader = {
  count: 0,
  downloadId: 0,
}
</script>

<style>
#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: rgba(44,62,80,1);
  margin-top: 60px;
}
.n-message-wrapper {
  max-width: 660px;
}
.n-message-container.n-message-container--top {
  top: 37px;
}
.n-data-table .n-data-table-table {
  word-break: normal;
}
.n-data-table .n-data-table-tr:not(.n-data-table-tr--summary):hover > .n-data-table-td {
    background-color: rgb(233 248 255);
}
.n-data-table .n-data-table-tr.n-data-table-tr--striped {
    background-color: rgb(233 248 255);
}
.n-data-table .n-data-table-tr.n-data-table-tr--striped .n-data-table-td {
    background-color: rgb(233 248 255);
}
.n-data-table .n-data-table__pagination {
    justify-content: center;
}
.n-image-preview-overlay {
  background: rgba(255, 255, 255, .33);
}
.n-image-preview {
  background: #fff;
  padding: 15px;
}
</style>
