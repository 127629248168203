<template>
  <n-card title="Settings">
      <strong class="details-label">App Version:</strong> {{ version }}<br>
      <strong class="details-label">Printer Name:</strong> {{ printerName }}<br>
      <strong class="details-label">Printer Type:</strong> {{ formattedPrinterType }} product print run<br>
      <strong class="details-label">Printer Serial:</strong> {{ printerSerial }}<br>
      <strong class="details-label">Printer UUID:</strong> {{ printerUuid }}
  </n-card>
  <n-card>
    <n-space class="settings-intro">
      Update the apps basic settings below.
    </n-space>
    <n-form size="large" ref="formRefSettings" :model="formValueSettings" :rules="rulesSettings">
      <n-form-item-row label="Partner Name" path="name">
        <n-input
          id="name"
          type="text"
          placeholder="Enter the Partners name..."
          v-model:value="formValueSettings.name">
        </n-input>
      </n-form-item-row>
      <n-form-item label="Select locale" path="locale">
        <n-select
          id="locale"
          v-model:value="formValueSettings.locale"
          placeholder="Select locale..."
          :options="localeOptions"
        />
      </n-form-item>
      <n-form-item label="Select artwork folder name" path="folder">
        <n-select
          id="folder"
          v-model:value="formValueSettings.folder"
          placeholder="Select folder..."
          :options="folderOptions"
        />
      </n-form-item>
      <n-form-item label="Select delay to enable past job delete ability" path="delay">
        <n-select
          id="delay"
          v-model:value="formValueSettings.delay"
          placeholder="Select delete delay..."
          :options="delayOptions"
        />
      </n-form-item>
      <n-form-item-row label="PC Name" path="pcname">
        <n-input
          id="pcname"
          type="text"
          placeholder="Enter the PC name..."
          v-model:value="formValueSettings.pcname">
        </n-input>
      </n-form-item-row>
      <n-form-item-row>
        <n-button type="primary" size="large" block :loading="isLoadingSettings" @click="updateSettings">
          Update Settings
        </n-button>
      </n-form-item-row>
    </n-form>
  </n-card>
  <n-card>
    <n-space class="settings-intro">
      Click the button below to select or change the folder for the downloading artwork and files.
    </n-space>
    <n-space>
      <div class="no-form">
        <n-button type="primary" size="large" block @click="selectArtworkFolder">
          Select Artwork Folder
        </n-button>
      </div>
    </n-space>
  </n-card>
  <n-card>
    <n-space class="settings-intro">
      If Merchr support has provided you with a new access token, paste it in below.
    </n-space>
    <n-form size="large" ref="formRefConnect" :model="formValueToken" :rules="rulesConnect">
      <n-form-item-row label="Merchr Hub Access Token" path="token">
        <n-input
          id="token"
          type="password"
          show-password-on="click"
          placeholder="Enter the Merchr Hub access token..."
          v-model:value="formValueToken.token">
          <template #password-visible-icon>
            <app-icon type="EyeSlashRegular"/>
          </template>
          <template #password-invisible-icon>
            <app-icon type="EyeRegular"/>
          </template>
        </n-input>
      </n-form-item-row>
      <n-form-item-row>
        <n-button type="primary" size="large" block :loading="isLoading" @click="updateAccessToken">
          Update Access Token
        </n-button>
      </n-form-item-row>
    </n-form>
  </n-card>
  <n-card>
    <n-space class="settings-intro">
      If Merchr support has asked you to select a new printer, use the dropdown below to select the new printer.
    </n-space>
    <n-form size="large" ref="formRefPrinter" :model="formValuePrinter">
      <n-form-item label="Select printer" path="printer">
        <n-select
          id="printer"
          v-model:value="formValuePrinter.printer"
          placeholder="Select printer..."
          :options="printerOptions"
        />
      </n-form-item>
      <n-form-item-row>
        <n-button type="primary" size="large" block :loading="isLoadingPrinter" @click="setPrinter">
          Set Printer
        </n-button>
      </n-form-item-row>
    </n-form>
  </n-card>
</template>
<script src="./PrinterSettings.js" />
<style lang="scss" scoped src="./PrinterSettings.scss"/>
