<template>
  <n-card :title="headerText">
    Jobs with 'printing' status will always show at the top of the list, jobs with 'ready' status will show next and the rest of the jobs will show after.
    The status blocks below also act as a job filter, simply click one and the jobs will be filtered, a blue button will show allowing you to reset.
    <n-divider id="header-divider" />
    <n-page-header>
      <n-grid :cols="6">
      <n-gi>
        <n-statistic v-bind:class="(newJobCount > 0)?'job-stats green':'job-stats'" label="New" :value="newJobCount" @click="handleNewFilter" />
      </n-gi>
      <n-gi>
        <n-statistic v-bind:class="(updatedJobCount > 0)?'job-stats green':'job-stats'" label="Updated" :value="updatedJobCount" @click="handleUpdatedFilter" />
      </n-gi>
      <n-gi>
        <n-statistic v-bind:class="(downloading > 0)?'job-stats orange':'job-stats'" label="Downloading" :value="downloading" @click="handleDownloadFilter" />
      </n-gi>
      <n-gi>
        <n-statistic v-bind:class="(ready > 0)?'job-stats green':'job-stats'" label="Ready For Print" :value="ready" @click="handleReadyFilter" />
      </n-gi>
      <n-gi>
        <n-statistic v-bind:class="(printing > 0)?'job-stats green':'job-stats'" label="Printing" :value="printing" @click="handlePrintingFilter" />
      </n-gi>
      <n-gi>
        <n-statistic v-bind:class="(issue > 0)?'job-stats red':'job-stats'" label="Issue" :value="issue" @click="handleIssueFilter" />
      </n-gi>
      </n-grid>
    </n-page-header>
  </n-card>
  <n-card>
    <n-space id="jobs-total-bar">
      <strong>Total Jobs:</strong> {{ totalJobCount }}
      <n-input-group>
        <n-input size="tiny" placeholder="Search jobs" v-model:value="searchValue" @keydown.enter="performSearch" />
        <n-button type="primary" size="tiny" @click="performSearch" ghost>
          Search
        </n-button>
      </n-input-group>
      <n-button size="tiny" type="primary" v-show="showClear === true" @click="clearAllFilters">
        Show All Jobs
      </n-button>
    </n-space>
  </n-card>
  <n-data-table id="jobs-table" :columns="columns" :data="jobs" :row-props="rowProps" striped />
  <n-modal v-model:show="showModal">
    <n-card
      style="width: 50%; min-width: 300px; max-width: 600px;"
      title="Job Details"
      :bordered="true"
      aria-modal="false"
    >
      <div v-html='modalContent' />
    </n-card>
  </n-modal>
  <n-modal v-model:show="showStatusModal">
    <n-card
      style="width: 50%; min-width: 200px; max-width: 400px;"
      title="Update Job Status"
      :bordered="true"
      aria-modal="false"
    >
      <div class="status-modal-intro">
        Select the new job status and click the update button.
      </div>
      <n-select
        id="job-status"
        v-model:value="statusValue"
        placeholder="Select status..."
        :options="statusOptions"
      />
      <template #action>
        <n-space justify="end">
          <n-button @click="closeStatusModal">
              Close
          </n-button>
          <n-button color="#0ea1ca" @click="updateStatus">
              Update
          </n-button>
        </n-space>
      </template>
    </n-card>
  </n-modal>
  <n-modal v-model:show="showIssueModal">
    <n-card
      style="width: 50%; min-width: 200px; max-width: 400px;"
      title="Report Issue"
      :bordered="true"
      aria-modal="false"
    >
      <div class="status-modal-intro">
        Detail the issue in the text area below and click the report button.
      </div>
      <n-input
        v-model:value="issueValue"
        type="textarea"
        placeholder="Write about the issue here..."
      />
      <template #action>
        <n-space justify="end">
          <n-button @click="closeIssueModal">
              Close
          </n-button>
          <n-button type="error" @click="reportIssue">
              Report
          </n-button>
        </n-space>
      </template>
    </n-card>
  </n-modal>
</template>
<script src="./DashboardMain.js" />
<style lang="scss" src="./DashboardMain.scss" />
