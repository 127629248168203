<template>
  <n-card :title="headerText">
    Below are all the past jobs that have been processed by this printer.
  </n-card>
  <n-card>
    <n-space id="jobs-total-bar">
      <strong>Total Past Jobs:</strong> {{ totalJobCount }}
      <n-input-group>
        <n-input size="tiny" placeholder="Search past jobs" v-model:value="searchValue" @keydown.enter="performSearch" />
        <n-button type="primary" size="tiny" @click="performSearch" ghost>
          Search
        </n-button>
      </n-input-group>
      <n-button size="tiny" type="primary" v-show="showClear === true" @click="clearAllFilters">
        Show All Jobs
      </n-button>
    </n-space>
  </n-card>
  <n-data-table id="jobs-table" :columns="columns" :data="jobs" :pagination="pagination" striped />
  <n-modal v-model:show="showModal">
    <n-card
      style="width: 50%; min-width: 300px; max-width: 600px;"
      title="Job Details"
      :bordered="false"
      aria-modal="false"
    >
      <div v-html='modalContent' />
    </n-card>
  </n-modal>
</template>
<script src="./PastJobs.js" />
<style lang="scss" scoped src="./PastJobs.scss" />
