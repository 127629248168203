import { createApp } from 'vue';
import naive from 'naive-ui';
import { Icon } from '@vicons/utils';
import AppIcon from '@/components/AppIcon.vue';
import router from '@/router/index';
import IndexedDbService from "@/services/indexedDBService.js";
import AppLayout from '@/layouts/AppLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import App from '@/App.vue';
import '@/registerServiceWorker';

const indexedDbService = new IndexedDbService();

const app = createApp(App);
app.config.globalProperties.dbTables = indexedDbService.getTables();
app.config.globalProperties.apiAddress = process.env.VUE_APP_API_ENDPOINT;
app.config.globalProperties.wsAddress = process.env.VUE_APP_WEBSOCKET_ENDPOINT;

app.use(router);
app.use(naive);
app.use(Icon);

app.component('app-layout', AppLayout);
app.component('auth-layout', AuthLayout);
app.component('app-icon', AppIcon);

app.mount('#app');
