import { ref, h } from 'vue';
import { RouterLink } from 'vue-router';
import AppIcon from '@/components/AppIcon.vue';

export default ({
  setup() {
    const renderIcon = (icon) => () => h(AppIcon, { type: icon });

    const menuOptions = [
      {
        label: () => h(RouterLink, { to: '/' }, { default: () => 'Jobs Dashboard' }),
        key: 'dashboard',
        icon: renderIcon('Print24Filled'),
      },
      {
        label: () => h(RouterLink, { to: '/past' }, { default: () => 'Past Jobs' }),
        key: 'pastJobs',
        icon: renderIcon('Print24Regular'),
      },
      {
        label: () => h(RouterLink, { to: '/history' }, { default: () => 'History' }),
        key: 'history',
        icon: renderIcon('HistorySharp'),
      },
      {
        label: () => h(RouterLink, { to: '/data' }, { default: () => 'Backup/Restore' }),
        key: 'data',
        icon: renderIcon('Database'),
      },
      {
        label: () => h(RouterLink, { to: '/settings' }, { default: () => 'Settings' }),
        key: 'settings',
        icon: renderIcon('Settings24Filled'),
      },
    ];
    
    return {
      renderIcon,
      menuOptions
    }
  },
  data: () => {
    return {
      collapsed: ref(false),
    }
  },
  watch: {
    collapsed(val) {
      localStorage.setItem("collapsed", val);
    }
  },
  async beforeMount() {
    const userCollapsed = localStorage.getItem("collapsed");
    if (userCollapsed !== null) {
      if (userCollapsed == 'false') {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    }
  },
})