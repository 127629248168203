<template>
  <n-icon v-bind="props">
    <icon/>
  </n-icon>
</template>
<script setup>
import { NIcon } from 'naive-ui';
import {
  EyeRegular, 
  EyeSlashRegular, 
  Bell, BellRegular, 
  DotCircleRegular, 
  User, 
  AngleLeft, 
  AngleRight, 
  Sync, 
  Edit, 
  TrashAlt, 
  Search, 
  CheckCircleRegular, 
  TimesCircleRegular, 
} from '@vicons/fa';
import {
  Print24Regular, 
  Print24Filled, 
  Settings24Filled,
  Status24Filled,
  ErrorCircle24Filled,
} from '@vicons/fluent';
import {
  Connect,
} from '@vicons/carbon';
import {
  HistorySharp,
  FactCheckFilled,
  CloudDownloadSharp,
  CheckCircleOutlineSharp,
} from '@vicons/material';
import {
  Database,
  DatabaseImport,
  DatabaseExport,
} from '@vicons/tabler';
import { computed } from 'vue';

const icons = {
  EyeRegular, 
  EyeSlashRegular, 
  Bell, BellRegular, 
  DotCircleRegular, 
  User, 
  AngleLeft, 
  AngleRight, 
  Sync, 
  Edit, 
  TrashAlt, 
  Search, 
  CheckCircleRegular, 
  TimesCircleRegular, 
  Print24Regular, 
  Print24Filled, 
  Settings24Filled,
  Connect,
  HistorySharp,
  FactCheckFilled,
  Status24Filled,
  ErrorCircle24Filled,
  CloudDownloadSharp,
  Database,
  DatabaseImport,
  DatabaseExport,
  CheckCircleOutlineSharp,
};
const props = defineProps({
  ...NIcon.props,
  type: {
    type: String,
    default: 'fallback',
  },
});
const icon = computed({
  get: () => icons[props.type],
});
</script>
