<template>
  <n-h2>
    Setup Printer Application
  </n-h2>
  <n-space v-show="intro === true" justify="center" class="setup-intro">
    The first step is to enter the Merchr Hub access token and click "connect to Merchr Hub",
    on success, you will be able to select the printer to attach to this app and then the 
    folder for downloading artwork and files.
  </n-space>
  <n-form v-show="connectForm === true" size="large" ref="formRefConnect" :model="formValueConnect" :rules="rulesConnect" @keydown.enter="authenticate">
    <n-form-item-row label="Merchr Hub Access Token" path="token">
      <n-input
        id="token"
        type="password"
        show-password-on="click"
        placeholder="Enter the Merchr Hub access token..."
        v-model:value="formValueConnect.token">
        <template #password-visible-icon>
          <app-icon type="EyeSlashRegular"/>
        </template>
        <template #password-invisible-icon>
          <app-icon type="EyeRegular"/>
        </template>
      </n-input>
    </n-form-item-row>
    <n-form-item-row>
      <n-button type="primary" size="large" block :loading="isLoading" @click="authenticate">
        Connect to Merchr Hub
      </n-button>
    </n-form-item-row>
  </n-form>
  <n-form v-show="printerForm === true" size="large" ref="formRefPrinter" :model="formValuePrinter">
    <n-form-item label="Select printer" path="printer">
      <n-select
        id="printer"
        v-model:value="formValuePrinter.printer"
        placeholder="Select printer..."
        :options="printerOptions"
      />
    </n-form-item>
    <n-form-item-row>
      <n-button type="primary" size="large" block :loading="isLoadingPrinter" @click="setPrinter">
        Set Printer
      </n-button>
    </n-form-item-row>
  </n-form>
  <n-card v-show="folderSelect === true" class="folder-selector-wrapper">
    <n-space class="setup-intro">
      Click the button below to select the folder for the downloading artwork and files.
    </n-space>
    <n-space>
      <div>
        <n-button type="primary" size="large" @click="selectArtworkFolder">
          Select Artwork Folder
        </n-button>
      </div>
    </n-space>
  </n-card>
  <n-card v-show="completed === true" class="finished-wrapper">
    <n-space justify="center" class="finished-notice">
      You are ready to go!
    </n-space>
    
    <n-space justify="center">
      <app-icon :type="'CheckCircleOutlineSharp'" size="240" color="#008000" />
    </n-space>
    
    <n-space justify="center">
      <n-button type="success" size="large" @click="finish">
        Let's Go
      </n-button>
    </n-space>
  </n-card>

  <n-space justify="center">
    Having problems connecting? Contact us at Merchr
  </n-space>
</template>

<script src="./SetupAuthentication.js"/>
<style lang="scss" scoped src="./SetupAuthentication.scss"/>
