import { h, ref } from 'vue';
import { NButton, NImage, NTag } from 'naive-ui';
import AppIcon from '@/components/AppIcon.vue';
import state from '@/state';

export default {
  name: 'JobDashboard',
  data: () => {
    return {
      printerName: null,
      jobs: ref([]),
      jobsData: null,
      newJobCount: ref(0),
      updatedJobCount: ref(0),
      totalJobCount: ref(0),
      downloading: ref(0),
      ready: ref(0),
      printing: ref(0),
      issue: ref(0),
      columns: [],
      showClear: ref(false),
      showModal: ref(false),
      modalContent: ref(''),
      statusValue: ref(null),
      issueValue: ref(null),
      showStatusModal: ref(false),
      showIssueModal: ref(false),
      statusOptions: ref([]),
      currentJobId: 0,
      searchValue: ref(null),
    }
  },
  computed: {
    headerText() {
      return 'Current Print Jobs - ' + this.printerName;
    }
  },
  methods: {
    capitalize(string) {
      let capitalized = []

      string.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      });

      return capitalized.join(' ');
    },
    rowProps(row) {
      const className = row.status.toLowerCase();
      return { class: className };
    },
    createColumns() {
      const self = this;
      return [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
        },
        {
          title: 'Date/Time',
          key: 'date',
          width: 110,
        },
        {
          title: 'Status',
          key: 'status',
          align: 'center',
          render(job) {
            let tagType = 'success';

            switch (job.status) {
              case 'Downloading':
              tagType = "warning";
              break;
              case 'Issue':
              tagType = "error";
              break;
            }

            return h(NTag, { type: tagType, round: true }, job.status);
          },
        },
        {
          title: 'Details',
          key: 'details',
          render(job) {
            return [
              h('strong', { class: 'details-title' }, 'Product Name'),
              h('div', { class: 'details-content' }, job.product_name),
              h('strong', { class: 'details-title' }, 'Product SKU'),
              h('div', { class: 'details-content' }, job.product_sku),
              h(NButton, 
                {  
                  size: 'tiny',
                  color: '#dcdfe6',
                  textColor: '#606266',
                  onClick: () => self.handleDetailsModal(job),
                }, 
                { 
                  default: () => [
                    h(AppIcon, { type: 'EyeRegular' }),
                    h('span', 'View more'),
                  ]
                },
              ),
            ];
          },
        },
        {
          title: 'Barcode',
          key: 'barcode',
          width: 120,
          render(job) {
            return [
              h('div', { class: 'barcode-text' }, job.barcode),
              h(NImage, { src: job.barcode_img, width: 120, lazy: true }),
            ];
          },
        },
        {
          title: 'Qty',
          key: 'qty',
          align: 'center',
        },
        {
          title: 'Product',
          key: 'product',
          align: 'center',
          render(job) {
            return  h(NImage, { src: job.product, width: 80, lazy: true });
          },
        },
        {
          title: 'Design',
          key: 'design',
          align: 'center',
          render(job) {
            return  h(NImage, { src: job.design, height: 100, lazy: true });
          },
        },
        {
          title: 'Job Sheet',
          align: 'center',
          key: 'job_sheet',
          render(job) {
            if(typeof job.job_sheet !== "undefined"
              && job.job_sheet !== null
              && job.job_sheet !== ''
            ) {
              return h(NButton, 
                {
                  tag: "a",
                  href: job.job_sheet,
                  target: "_blank",
                  size: 'tiny',
                  color: '#dcdfe6',
                  textColor: '#606266',
                },
                {
                  default: () => [
                    h(AppIcon, { type: 'EyeRegular' }),
                    h('span', 'Job Sheet'),
                  ]
                },
              );
            } else {
              return '';
            }
          }
        },
        {
          title: 'Actions',
          align: 'center',
          key: 'actions',
          render(job) {
            let acceptButtonDisabled = true;
            let statusButtonDisabled = false;
            let issueButtonDisabled = false;
            let artworkButtonDisabled = false;

            if (job.status === 'New') {
              acceptButtonDisabled = false;
              statusButtonDisabled = true;
              issueButtonDisabled = true;
              artworkButtonDisabled = true;  
            }
            if (job.status === 'Downloading') {
              artworkButtonDisabled = true;  
            }
            if (job.accepted === false) {
              acceptButtonDisabled = false;
              statusButtonDisabled = true;
              issueButtonDisabled = true;
              artworkButtonDisabled = true;  
            }

            return [
              h(NButton, 
                {
                  size: 'tiny',
                  type: 'success',
                  disabled: acceptButtonDisabled,
                  class: 'job-action-btn',
                  onClick: () => self.acceptJob(job),
                },
                {
                  default: () => [
                    h(AppIcon, { type: 'FactCheckFilled' }),
                    h('span', 'Accept Job'),
                  ]
                },
              ),
              h(NButton, 
                {
                  size: 'tiny',
                  type: 'primary',
                  disabled: statusButtonDisabled,
                  class: 'job-action-btn',
                  onClick: () => self.openStatusModal(job),
                },
                {
                  default: () => [
                    h(AppIcon, { type: 'Status24Filled' }),
                    h('span', 'Update Status'),
                  ]
                },
              ),
              h(NButton, 
                {
                  size: 'tiny',
                  type: 'error',
                  disabled: issueButtonDisabled,
                  class: 'job-action-btn',
                  onClick: () => self.openIssueModal(job),
                }, 
                {
                  default: () => [
                    h(AppIcon, { type: 'ErrorCircle24Filled' }),
                    h('span', 'Report Issue'),
                  ]
                },
              ),
              h(NButton, 
                {
                  size: 'tiny',
                  color: '#ff9244',
                  disabled: artworkButtonDisabled,
                  class: 'job-action-btn',
                  onClick: () => self.redownloadArtwork(job),
                },
                {
                  default: () => [
                    h(AppIcon, { type: 'CloudDownloadSharp' }),
                    h('span', 'Redownload Files'),
                  ]
                },
              ),
            ];
          },
        }
      ];
    },
    createStatsuOptions() {
      this.statusOptions = [
        {
          label: 'Preparing at Location',
          value: 8
        },
        {
          label: 'Printing at Location',
          value: 9
        },
        {
          label: 'Reprinting at Location',
          value: 10
        },
        {
          label: 'Completed',
          value: 14
        },
      ];
    },
    handleNewFilter() {
      this.showClear = true;
      this.processRecords('New');
    },
    handleUpdatedFilter() {
      this.showClear = true;
      this.processRecords('Updated');
    },
    handleDownloadFilter() {
      this.showClear = true;
      this.processRecords('Downloading');
    },
    handleReadyFilter() {
      this.showClear = true;
      this.processRecords('Ready');
    },
    handlePrintingFilter() {
      this.showClear = true;
     this.processRecords('Printing');
    },
    handleIssueFilter() {
      this.showClear = true;
      this.processRecords('Issue');
    },
    clearAllFilters() {
      this.showClear = false;
      this.searchValue = null;
      this.processRecords();
    },
    updateJobCount(value) {
      this.newJobCount = value;
    },
    updateTotalJobCount(value) {
      this.totalJobCount = value;
    },
    performSearch() {
      if (this.searchValue == '' || this.searchValue == null) {
        const message = 'Please enter a search term!';
        window.$message.warning(message, {
          closable: true,
          duration: 6000,
          keepAliveOnHover: true,
        });
        return;
      }

      this.showClear = true;
      this.processRecords();
    },
    closeStatusModal() {
      this.showStatusModal = false;
      this.currentJobId = 0;
      this.statusValue = null;
    },
    closeIssueModal() {
      this.showIssueModal = false;
      this.currentJobId = 0;
      this.issueValue = null;
    },
    openStatusModal(job) {
      this.currentJobId = job.id;
      this.showStatusModal = true;
    },
    openIssueModal(job) {
      this.currentJobId = job.id;
      this.showIssueModal = true;
    },
    async handleDetailsModal(job) {
      const partnerName = await state.getPartnerName();

      let attributes = '<br>';
      if(job.attributes !== null) {
        for (const [key, value] of Object.entries(job.attributes)) {
          const title = this.capitalize(key.replace("_", " "));
          attributes += `<strong>${title}:</strong> ${value}<br>`;
        }
      }

      let metaData = '<br>';
      if(job.meta_data !== null) {
        for (const [key, value] of Object.entries(job.meta_data)) {
          const title = this.capitalize(key.replace("_", " "));
          metaData += `<strong>${title}:</strong> ${value}<br>`;
        }
      }

      const content = `<div class="modal-details">
      <div class="details-left">
      <strong class="details-title">Product Name</strong>
      <div class="details-content">${job.product_name}</div>
      <strong class="details-title">Product SKU</strong>
      <div class="details-content">${job.product_sku}</div>
      <strong class="details-title">External Job ID</strong>
      <div class="details-content">${job.external_id}</div>
      </div>
      <div class="details-right">
      <strong class="details-title">Product Attributes</strong>
      <div class="details-content">
      ${attributes}
      </div>
      </div>
      </div>
      <div class="details-partner">
      <hr>
      <strong class="details-title">${partnerName} Details</strong>
      <div class="details-content">
      ${metaData}
      </div>
      </div>`;

      this.modalContent = content;
      this.showModal = true;
    },
    async acceptJob(job) {
      const sessionToken = await state.getSessionToken();
      const accepted = {
        type: "job_accepted",
        production_partner_job_id: job.id,
        session_token: sessionToken
      };

      window.$websocket.send(JSON.stringify(accepted));
    },
    async redownloadArtwork(job) {
      const locale = await state.getLocale();
      let jobDetails = await state.getPrintJob(`job_${job.id}`);
      jobDetails.status = 'Downloading';
      await state.addUpdatePrintJob(`job_${job.id}`, jobDetails);

      const message = 'The job files have started redownloading, the jobs list will be updated when completed';
      window.$message.success(message, {
        closable: true,
        duration: 6000,
      });

      // Update history
      const history = {
        type: 'File Redownload Request',
        job: job.id,
        message: `The job with ID: ${job.id} has started redownloading the job files`,
        date: new Date().toLocaleString(locale)
      };
      state.addHistory(history);
      
      this.processRecords();

      window.$newJobsHeader.downloadId = job.id;
      
      // Set status preparing on the Hub
      const sessionToken = await state.getSessionToken();
      const updated = {
        type: "update_status",
        production_partner_job_id: job.id,
        production_partner_job_status_id: 8,
        session_token: sessionToken
      };
      
      window.$websocket.send(JSON.stringify(updated));
    },
    async updateStatus() {
      const locale = await state.getLocale();

      // Set status
      let status = 'Ready';
      switch (this.statusValue) {
          case 9:
          status = 'Printing';
          break;
          case 10:
          status = 'Printing';
          break;
          case 14:
          status = 'Completed';
          break;
      }

      // Get job details and update status
      let jobDetails = await state.getPrintJob(`job_${this.currentJobId}`);
      jobDetails.status = status;
      await state.addUpdatePrintJob(`job_${this.currentJobId}`, jobDetails);

      const sessionToken = await state.getSessionToken();
      const updated = {
        type: "update_status",
        production_partner_job_id: this.currentJobId,
        production_partner_job_status_id: this.statusValue,
        session_token: sessionToken
      };

      window.$websocket.send(JSON.stringify(updated));

      // if completed, we need to move to past jobs
      if (this.statusValue == 14) {
        await state.addUpdatePrintPastJob(`job_${this.currentJobId}`, jobDetails);

        // Delete job
        await state.deletePrintJob(`job_${this.currentJobId}`);

        const message = 'The job has been successfully marked as completed and moved to past jobs';
        window.$message.success(message, {
          closable: true,
          duration: 6000,
        });

        // Update history
        const history = {
          type: 'Job Marked Completed',
          job: this.currentJobId,
          message: `The job with ID: ${this.currentJobId} has been marked as completed and moved to past jobs`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      } else {
        const message = `The job with ID: ${this.currentJobId} status has been successfully changed to ${status}`;
        window.$message.success(message, {
          closable: true,
          duration: 6000,
        });

        // Update history
        const history = {
          type: 'Job Status Updated',
          job: this.currentJobId,
          message: `The job record with ID: ${this.currentJobId} has changed its status to ${status}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }

      this.closeStatusModal();
    },
    async reportIssue() {
      if (this.issueValue === null || this.issueValue === '') {
        window.$message.warning(
          'The message field is required to report an issue!', 
          {
            closable: true,
            duration: 8000,
          }
        );
        return;
      }
      
      const locale = await state.getLocale();
      
      // Get job details and update status
      let jobDetails = await state.getPrintJob(`job_${this.currentJobId}`);
      jobDetails.status = 'Issue';
      jobDetails.messages.push({
        type: 'sent',
        title: 'Issue Reported',
        content: this.problemValue
      });
      await state.addUpdatePrintJob(`job_${this.currentJobId}`, jobDetails);

      const sessionToken = await state.getSessionToken();
      const issue = {
        type: "report_problem",
        production_partner_job_id: this.currentJobId,
        note: this.issueValue,
        session_token: sessionToken
      };

      window.$websocket.send(JSON.stringify(issue));
      
      // Update history
      const history = {
        type: 'Operator Reported an Issue',
        job: this.currentJobId,
        message: `The operator has reported an issue for job  with ID: ${this.currentJobId}. The Issue Message: ${this.problemValue}`,
        date: new Date().toLocaleString(locale)
      };
      state.addHistory(history);
      
      this.closeIssueModal();
    },
    async processRecords(filter) {
      if (typeof filter === "undefined") {
        filter = 'all';
      }

      this.jobsData = await state.getPrintJobs();
      const self = this;
      let rows = [];
      let printingRows = [];
      let readyRows = [];
      let totalJobs = 0;
      let newJobs = 0;
      let updatedJobs = 0;
      let downloadingJobs = 0;
      let readyJobs = 0;
      let printingJobs = 0;
      let issueJobs = 0;

      this.jobsData.forEach( function(job) {
        totalJobs += 1;

        switch (job.status) {
          case 'New':
          newJobs += 1;
          break;
          case 'Updated':
          updatedJobs += 1;
          break;
          case 'Downloading':
          downloadingJobs += 1;
          break;
          case 'Ready':
          readyJobs += 1;
          break;
          case 'Printing':
          printingJobs += 1;
          break;
          case 'Issue':
          issueJobs += 1;
          break;
        }

        // Check for filter
        if (filter !== 'all' && filter !== job.status) {
          return;
        }

        // Process search
        if (self.searchValue !== null) {
          let add = false;

          if (self.searchValue == job.production_partner_job_id) {
            add = true;
          }
          if (self.searchValue == job.barcode_value) {
            add = true;
          }
          if (self.searchValue == job.sku) {
            add = true;
          }
          if (self.searchValue == job.external_id) {
            add = true;
          }

          // Name search
          const lowerCaseSearch = self.searchValue.toLowerCase();
          const lowerCaseName = job.product_name.toLowerCase();
          if (lowerCaseName.includes(lowerCaseSearch)) {
            add = true;
          }

          if (!add) {
            return;
          }
        }

        if (job.status === 'Printing') {
          printingRows.push({
            id: job.production_partner_job_id,
            date: job.date.replace(',', ''),
            barcode: job.barcode_value,
            barcode_img: job.barcode_image_path,
            barcodes: job.barcodes,
            external_id: job.external_id,
            qty: job.quantity,
            product: job.product_with_design_image_path,
            design: job.production_partner_job_design_image_path,
            product_name: job.product_name,
            product_sku: job.sku,
            accepted: job.accepted,
            status: job.status,
            job_sheet: job.job_sheet,
            messages: job.messages,
            attributes: job.attributes,
            meta_data: job.meta_data,
            has_artwork: job.has_artwork,
            actions: null,
          });
        } else if (job.status === 'Ready') {
          readyRows.push({
            id: job.production_partner_job_id,
            date: job.date.replace(',', ''),
            barcode: job.barcode_value,
            barcode_img: job.barcode_image_path,
            barcodes: job.barcodes,
            external_id: job.external_id,
            qty: job.quantity,
            product: job.product_with_design_image_path,
            design: job.production_partner_job_design_image_path,
            product_name: job.product_name,
            product_sku: job.sku,
            accepted: job.accepted,
            status: job.status,
            job_sheet: job.job_sheet,
            messages: job.messages,
            attributes: job.attributes,
            meta_data: job.meta_data,
            has_artwork: job.has_artwork,
            actions: null,
          });
        } else {
          rows.push({
            id: job.production_partner_job_id,
            date: job.date.replace(',', ''),
            barcode: job.barcode_value,
            barcode_img: job.barcode_image_path,
            barcodes: job.barcodes,
            external_id: job.external_id,
            qty: job.quantity,
            product: job.product_with_design_image_path,
            design: job.production_partner_job_design_image_path,
            product_name: job.product_name,
            product_sku: job.sku,
            accepted: job.accepted,
            status: job.status,
            job_sheet: job.job_sheet,
            messages: job.messages,
            attributes: job.attributes,
            meta_data: job.meta_data,
            has_artwork: job.has_artwork,
            actions: null,
          });
        }
      });

      // Update rows
      const mergeReadyPrintingRows = printingRows.concat(readyRows);
      this.jobs = mergeReadyPrintingRows.concat(rows.reverse());

      // Update counts
      this.newJobCount = newJobs;
      window.$newJobsHeader.count = newJobs;
      this.updatedJobCount = updatedJobs;
      this.totalJobCount = totalJobs;
      this.downloading = downloadingJobs;
      this.ready = readyJobs;
      this.printing = printingJobs;
      this.issue = issueJobs;
    },
  },
  async beforeMount() {
    const self = this;
    const jobCount = window.$newJobs.count;
    const jobTotal = window.$newJobs.total;

    window.$newJobs = new Proxy(
      {
        new: false,
        updated: false,
        count: jobCount,
        total: jobTotal,
      },
      {
        set: function (target, key, value) {
          target[key] = value;

          if (key === 'count') {
            self.updateJobCount(value);
          }
          if (key === 'total') {
            self.updateTotalJobCount(value);
          }
          if ( (key === 'new' || key === 'updated') && value === true ) {
            self.processRecords();
            window.$newJobs.new = false;
            window.$newJobs.updated = false;
          }

          return true;
        },
      }
    );

    this.updateJobCount(jobCount);
    this.updateTotalJobCount(jobTotal);
    this.columns = await this.createColumns();
    this.createStatsuOptions();
  },
  async mounted() {
    this.processRecords();
    this.printerName = await state.getPrinterName();
  },
}