import { createRouter, createWebHashHistory } from 'vue-router';
import IndexedDbService from "@/services/indexedDBService.js";
import SetupAuthentication from '@/views/Setup/SetupAuthentication.vue';
import DashboardMain from '@/views/Dashboard/DashboardMain.vue';
import PastJobs from '@/views/PastJobs/PastJobs.vue';
import HistoryData from '@/views/History/HistoryData.vue';
import DataBackupRestore from '@/views/Data/DataBackupRestore.vue';
import PrinterSettings from '@/views/Settings/PrinterSettings.vue';

const indexedDbService = new IndexedDbService();
indexedDbService.getDb();
const dbTables = indexedDbService.getTables();

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardMain,
    meta: {
      title: 'Merchr Print Jobs Dashboard',
    },
  },
  {
    path: '/setup',
    name: 'setup',
    component: SetupAuthentication,
    meta: {
      layout: 'auth-layout',
      title: 'Merchr Print Setup',
    },
  },
  {
    path: '/past',
    name: 'past',
    component: PastJobs,
    meta: {
      title: 'Merchr Print Past Jobs',
    },
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryData,
    meta: {
      title: 'Merchr Print History',
    },
  },
  {
    path: '/data',
    name: 'data',
    component: DataBackupRestore,
    meta: {
      title: 'Merchr Print Backup/Restore',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: PrinterSettings,
    meta: {
      title: 'Merchr Print Settings',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// eslint-disable-next-line consistent-return
router.beforeEach(async (to) => {
  const publicPages = ['setup'];
  const authRequired = !publicPages.includes(to.name);
  const token = await indexedDbService.get(dbTables.settings, 'token');

  if(authRequired
    && (typeof token === "undefined" 
    || token === null 
    || token === '')) {
    return '/setup';
  }
});

router.afterEach((to) => {
  document.title = to.meta.title ?? 'Merchr Print';
});

export default router
